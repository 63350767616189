import Image from 'next/image'
import React from 'react'

const PaymentOptions = () => {
    return (
        <div className='mt-8 flex gap-2 items-center justify-center w-full'>
            <h3 className='font-inter font-semibold text-wireframe-heading leading-[19.2px]'>Secured Checkout With</h3>
            <Image src="/icons/razorpay.svg" loading="lazy" width={80} height={40} alt='Razor pay Icon' />
        </div>
    )
}

export default PaymentOptions