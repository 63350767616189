import { cn } from '@/lib/utils';
import Link from 'next/link';
import React from 'react';
import Icon from '../Icon';

/**
 * Button component
 * @param {ButtonProps} props - The props for the Button component.
 * @returns {JSX.Element} The rendered Button component.
 */

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
  children: React.ReactNode;
  href?: string;
  className?: string;
  disabled?: boolean;
}


const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  leftIcon,
  rightIcon,
  onClick,
  disabled = false,
  className,
  href,
  ...props 
}) => {
  const baseClasses = 'inline-flex items-center justify-center font-inter px-3 py-2 text-base font-normal rounded-lg border duration-300 leading-[22.4px]';
  const variantClasses = {
    primary: 'bg-primary-5 text-white border-primary-5 hover:bg-transparent hover:text-primary-5 hover:shadow-shadow-btn',
    secondary: 'bg-wireframe-heading text-white border-wireframe-grey hover:bg-transparent hover:text-wireframe-heading hover:shadow-shadow-btn',
    outline: 'bg-transparent text-gray-6 border-gray-3 text-sm hover:bg-gray-1',
  };
  const iconClasses = 'flex items-center';

  const commonClasses = cn(`${baseClasses} ${variantClasses[variant]} ${iconClasses} ${disabled ? 'bg-gray-2 text-gray-5 cursor-not-allowed' : ''} ${className}`);

  return href ? (
    <Link
      href={href}
      className={commonClasses}
      onClick={disabled ? undefined : onClick}
      aria-disabled={disabled}
    >
      {leftIcon && <span className="mr-2">{leftIcon}</span>}
      <span className='font-normal'>{children}</span>
      {rightIcon && <span className="ml-2">{rightIcon}</span>}
    </Link>
  ) : (
    <button
      {...props}
      className={commonClasses}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      aria-disabled={disabled}
    >
      {leftIcon && <span className="mr-2">{leftIcon}</span>}
      <span >{children}</span>
      {rightIcon && <span className="ml-2">{rightIcon}</span>}
    </button>
  );
};

export const CustomPrevButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="custom-nav-button">
    <Icon name='arrowLeft' />
  </button>
);

export const CustomNextButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <button onClick={onClick} className="custom-nav-button">
    <Icon name='arrowLeft' className='rotate-180' />
  </button>
);

export default Button;
