import { formatCurrency } from '@/lib/utils';
import { AppState } from '@/store/store';
import Link from 'next/link';
import React from 'react';
import { useSelector } from 'react-redux';

const PricingDetails: React.FC = () => {
    const { data: cartData, loading: cartLoading, error: cartError } = useSelector((state: AppState) => state.happinessCart);
    const loyalty_points = useSelector((state: AppState) => state.happinessCart.loyalty_points) || 0;



    return (
        <div className="mt-8">
            <div className="text-sm mt-4 mb-6">
                <div className='mb-4 border-b border-gray-[#F1F1F1]'>
                    <div className="flex justify-between pb-2.5">
                        <span className='text-sm text-[#21272A] font-inter leading-[22.4px]'>Sub Total</span>
                        <span className='text-[#21272A] font-inter font-semibold leading-[19.2px]'>{cartData && cartData?.total_price && formatCurrency(cartData?.total_price, 'en-IN', 'INR')}</span>
                    </div>
                    <div className="flex justify-between pb-2.5">
                        <span className='text-sm text-[#21272A] font-inter leading-[22.4px]'>CGST</span>
                        <span className='text-[#21272A] font-inter font-semibold leading-[19.2px]'>₹0.00</span>
                    </div>
                    <div className="flex justify-between pb-2.5">
                        <span className='text-sm text-[#21272A] font-inter leading-[22.4px]'>SGST</span>
                        <span className='text-[#21272A] font-inter font-semibold leading-[19.2px]'>₹0.00</span>
                    </div>

                    {(loyalty_points && loyalty_points > 0) ? <div className="flex justify-between pb-2.5">
                        <span className='text-sm text-[#21272A] font-inter leading-[22.4px]'>Loyalty points</span>
                        <span className='text-[#21272A] font-inter font-semibold leading-[19.2px]'>-{formatCurrency(loyalty_points, 'en-IN', 'INR')}</span>
                    </div> : ""}

                </div>
                <div className="flex justify-between font-bold pb-4">
                    <span className='text-sm text-[#21272A] font-inter leading-[22.4px]'>Total</span>
                    <span className='text-[#21272A] font-inter font-semibold leading-[19.2px]'>{cartData && cartData?.total_price && formatCurrency(cartData?.total_price - loyalty_points, 'en-IN', 'INR')}</span>
                </div>
                <p className="text-xs text-wireframe-heading font-inter">
                    *estimated prices and subject to change. all prices in INR
                </p>
                <Link target='_blank' href="https://www.barbequenation.com/terms-conditions" className="text-[#2F80ED] text-xs mt-1.5">Read More</Link>
            </div>
        </div>
    );
};

export default PricingDetails;
