import React, { useState } from 'react';
import Icon from './Icon';
import { cn } from '@/lib/utils';
import { useSession } from 'next-auth/react';
import { openPopup } from '@/store/slices/auth/signPopupSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import Loader from './Loader';



interface CounterProps {
    initialCount?: number;
    btnColor?: string;
    borderColor?: string;
    textColor?: string;
    iconColor?: string;
    increment?: () => void
    decrement?: () => void
    loader?: boolean
}

const Counter: React.FC<CounterProps> = ({
    initialCount = 0,
    btnColor = 'bg-primary-5',
    borderColor = 'border-primary-5',
    textColor = 'text-primary-5',
    iconColor = 'fill-ubq',
    increment,
    decrement,
    loader = false
}) => {
    const dispatch = useDispatch<AppDispatch>();
    // check if login session is available
    const { data: session } = useSession();

    return (
        <>
            {initialCount > 0 ? (
                <div className={cn('flex items-center border rounded-lg h-[35px] md:w-24 justify-around max-[1023px]:bg-white md:justify-center', borderColor)}>
                    <button
                        className={cn('w-7 h-7 flex justify-center items-center', textColor)}
                        onClick={() => {
                            decrement && decrement()
                        }}
                    >
                        <Icon name='minusCounter' size={16} className={cn(iconColor)} />
                    </button>
                    <span className={cn('mx-2 font-inter text-sm leading-[22.4px]', textColor)}>{initialCount}</span>
                    <button
                        className={cn('w-7 h-7 flex justify-center items-center', textColor)}
                        onClick={() => {
                            increment && increment()
                        }}
                    >
                        <Icon name='plusCounter' size={16} className={cn(iconColor)} />
                    </button>
                </div>
            ) : (
                <button
                    className={cn('px-4 py-2 text-white rounded-lg font-inter text-sm md:text-base font-semibold flex justify-center items-center', btnColor)}
                    style={{ backgroundColor: btnColor }}
                    onClick={() => {
                        if (!session) {
                            dispatch(openPopup(true))
                            return;
                        }
                        increment && increment()
                    }}
                >
                    {loader ? <Loader /> : 'ADD'}
                </button>
            )}
        </>
    )

};

export default Counter;