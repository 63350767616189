import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { SidebarProps, SidebarRef } from '@/types/next';
import Icon from '../Icon';
import { cn } from '@/lib/utils';


const Sidebar = forwardRef<SidebarRef, SidebarProps>(({ isOpen, onClose, children, heading, footer, className }, ref) => {
    const sidebarRef = useRef<HTMLDivElement>(null);

    // Effect to handle body scroll
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    useImperativeHandle(ref, () => ({
        scrollUp: () => {
            if (sidebarRef.current) {
                // Scroll up by 576px.
                const currentScrollTop = sidebarRef.current.scrollTop;
                const newScrollTop = Math.max(currentScrollTop + 576, 0);
                sidebarRef.current.scrollTo({ top: newScrollTop, behavior: 'smooth' });
            }
        },

    }), []);
    return (
        <>
            {/* Overlay background */}
            <div
                className={`fixed top-0 left-0 w-full h-full min-h-[100vh] inset-0 bg-black bg-opacity-50 z-[51] transition-opacity duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                aria-hidden={!isOpen}
            ></div>
            {/* Sidebar container */}
            <aside
                role="Sidebar"
                aria-modal="true"
                aria-labelledby="sidebar"
                className={cn(`fixed top-0 right-0 h-full z-[51] w-full max-w-[426px]  bg-white shadow-[-30px_22px_24px_0px_#00000014;] duration-500 transform transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-[110%]'} ${className}`)}>
                {/* Header with close button */}
                <div className='flex gap-3 items-center justify-between p-5 md:px-6 md:py-5 bg-white shadow-sm'>
                    {heading}
                    <button onClick={onClose} aria-label="Close sidebar">
                        <Icon name='close' />
                    </button>
                </div>
                {/* Sidebar content */}
                <div ref={sidebarRef} className={`px-6 py-5 ${footer ? 'h-[calc(100%-134px)]' : 'h-[calc(100%-90px)]'} overflow-y-auto scrollbar-hidden`}>
                    {children}
                </div>
                {/* Footer if provided */}
                {footer && (
                    <div className="p-5 md:px-6 md:py-5 bg-white fixed bottom-0 left-0 right-0 w-full shadow-[0px_-1px_6px_0px_#00000040;] z-10">
                        {footer}
                    </div>
                )}
            </aside>
        </>
    );
});
Sidebar.displayName = 'Sidebar';
export default Sidebar;
