import React, { useEffect } from 'react';
import Counter from '@/components/Counter';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store/store';
import { formatCurrency, findItemByKeyValue } from '@/lib/utils';
import useFetch from '@/hooks/useFetch';
import { OrderItem } from '@/types/next';
import { fetchDeleteHappinessCart, fetchHappinessCart } from '@/store/slices/happiness-card/happinessCartSlice';
import { useSession } from 'next-auth/react';


const HappinessCard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data: session } = useSession()
    const { data: cartData, loading: cartLoading, error: cartError } = useSelector((state: AppState) => state.happinessCart);
    const { data, error, fetchData, loading } = useFetch();

    // Function to increment item quantity in the cart
    const increment = async (order_item_id: number) => {
        let body: Record<string, any> = {};
        // Find existing item in the cart
        const existingItem = cartData && cartData?.order_items.find(item => item.order_item_id === order_item_id);
        if (!existingItem) return
        // Prepare request body for updating item quantity
        let method = 'PUT';
        body = {
            order_item_id: existingItem.order_item_id,
            quantity: existingItem.quantity + 1
        };

        // Send the request to update the item quantity
        await fetchData('/cart', {
            method,
            body: JSON.stringify({
                ...body,
                ...(cartData?.order_id && { order_id: cartData.order_id })
            })
        });
    };

    // Function to decrement item quantity or remove item if quantity is zero
    const decrement = (order_item_id: number) => {
        if (cartData && cartData?.order_items?.length > 0) {
            // Find existing item in the cart
            let existing = cartData?.order_items.find(item => item.order_item_id === order_item_id);
            if (existing && existing && (existing.quantity - 1) > 0) {
                // If quantity is greater than 1, update the item quantity
                fetchData("/cart", {
                    method: 'PUT',
                    body: JSON.stringify({
                        order_item_id: existing?.order_item_id,
                        quantity: existing && existing?.quantity - 1,
                        ...(cartData?.order_id && {
                            order_id: cartData?.order_id,
                        })
                    })
                })
            } else {
                // If quantity is 0, remove the item from the cart
                dispatch(fetchDeleteHappinessCart({ session: session ? true : false , url :`${process.env.NEXT_PUBLIC_SITE_URL}/api/v1/cart/${cartData?.order_id}/${existing?.order_item_id}` }));
            }
        }
    };

    // Function to find an item in the cart by its ID
    const findInCart = (order_item_id: number) => {
        if (cartData && cartData?.order_items?.length > 0)
            return findItemByKeyValue(cartData?.order_items, 'order_item_id', order_item_id);
    }

    // Effect hook to dispatch an action to fetch the cart data
    useEffect(() => {
        if (data) {
            // dispatch(fetchHappinessCart())
            dispatch(fetchHappinessCart({ session: session ? true : false }))

        }
    }, [data])

    return (
        <>
            {/* Render cart items if available */}
            {cartData && cartData?.order_items?.length > 0 && cartData?.order_items?.map((item: OrderItem) => <>
                <div className='p-4 border rounded-lg border-gray-2 shadow-sm mb-4'>
                    <h2 className='font-inter font-semibold text-xl text-wireframe-heading leading-6'>
                        {item.title}
                    </h2>
                    <div className='flex justify-between items-end mt-3'>
                        <span className='font-inter font-semibold text-xl text-wireframe-heading leading-6'>
                            {(item.quantity && item.unit_price) && formatCurrency((item.quantity * item.unit_price), 'en-IN', 'INR')}
                        </span>
                        <Counter initialCount={item?.quantity} increment={() => increment(item?.order_item_id)} decrement={() => decrement(item?.order_item_id)} />
                    </div>
                </div>
            </>)}
        </>
    );
};

export default HappinessCard;
